

<template>
  <!--begin::Wrapper-->
  <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
    <!--begin::Form-->
    <Form
      class="form w-100"
      id="kt_login_signin_form"
      @submit="onSubmitLogin"
      :validation-schema="phone"
    >
      <!--begin::Heading-->
      <div class="text-center mb-10">
        <!--begin::Title-->
        <h1 class="text-dark mb-3">{{ $t("login.title") }}</h1>
        <!--end::Title-->
      </div>
      <!--begin::Heading-->

      <div class="mb-10 bg-light-info p-8 rounded">
        <div class="text-info">
          <strong>
            {{ $t("login.desc") }}
          </strong>
        </div>
      </div>

      <div class="fv-row mb-10">
        <label class="form-label fs-6 fw-bolder text-dark">{{
          $t("login.country")
        }}</label>
        <div class="d-flex align-items-center">
          <select
            v-model="generateOTPModel.countryCode"
            class="form-select form-select-lg form-select-solid"
            required
          >
            <option
              v-for="country in countryList"
              :key="country.country.id"
              :value="country.country.id"
            >
              <img
                :src="country.country.flagUrl"
                :alt="country.country.name"
                class="me-2"
                width="24"
                height="24"
              />{{ country.country.name }}
            </option>
          </select>
        </div>
      </div>

      <!--begin::Input group-->
      <div class="fv-row mb-10">
        <!--begin::Label-->
        <label class="form-label fs-6 fw-bolder text-dark">{{
          $t("login.phone")
        }}</label>
        <!--end::Label-->

        <!--begin::Input-->
        <Field
          class="form-control form-control-lg form-control-solid"
          type="text"
          v-model="generateOTPModel.phoneNumber"
          name="phone"
          autocomplete="off"
          placeholder="(###) ###-##-##"
          v-mask="'(###) ###-##-##'"
          maxlength="15"
        />
        <!--end::Input-->
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="phone" />
          </div>
        </div>
      </div>
      <!--end::Input group-->

      <!--begin::Actions-->
      <div class="text-center">
        <!--begin::Submit button-->
        <button
          type="submit"
          ref="submitButton"
          id="kt_sign_in_submit"
          class="btn btn-lg btn-primary w-100 mb-5"
        >
          <span class="indicator-label"> {{ $t("login.title") }} </span>

          <span class="indicator-progress">
            {{ $t("common.pleaseWait") }}
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
        <!--end::Submit button-->
      </div>
      <!--end::Actions-->
    </Form>
    <!--end::Form-->
  </div>
  <OTP_Modal></OTP_Modal>
  <!--end::Wrapper-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/presentation/store/enums/StoreEnums";
import { useStore } from "vuex";
import OTP_Modal from "@/presentation/views/OTPModule.vue";
import * as Yup from "yup";
import { mask } from "vue-the-mask";
import { useI18n } from "vue-i18n";
import { GenerateOTPModel } from "@/domain/auth/generateOTP/model/GenerateOTPModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { CountryListModel } from "@/domain/country/countryList/model/CountryListModel";
import * as bootstrap from "bootstrap";

export default defineComponent({
  name: "login",
  components: {
    Field,
    Form,
    ErrorMessage,
    OTP_Modal,
  },
  setup() {
    const { t } = useI18n();
    const swalNotification = new SwalNotification();
    const store = useStore();
    const otpController = store.state.ControllersModule.otpController;
    const countryController = store.state.ControllersModule.countryController;

    const submitButton = ref<HTMLElement | null>(null);
    const submit = ref<boolean>(true);
    const generateOTPModel = ref<GenerateOTPModel>({
      countryCode: 397,
    });

    const countryList = ref<CountryListModel[]>([]);

    //Create form validation object
    const phone = Yup.object().shape({
      phone: Yup.string()
        .required(t("validators_error.required"))
        .label("Phone")
        .min(15, t("validators_error.phoneNumberFull")),
    });

    //Form submit function
    const onSubmitLogin = () => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      generateOTPModel.value.phoneNumber = generateOTPModel.value.phoneNumber
        ?.toString()
        .replace(/\D/g, "");

      store.dispatch("phoneNumber", generateOTPModel.value.phoneNumber);
      store.dispatch("countryCode", generateOTPModel.value.countryCode);

      otpController
        .generateOTP(generateOTPModel.value)
        .then((response) => {
          if (response.isSuccess) {
            submit.value = true;
            store.dispatch("expireTime", response.getValue().expireTime);
            submitButton.value?.removeAttribute("data-kt-indicator");
            store.dispatch(
              "remainingAttempts",
              response.getValue().remainingAttempts
            );
            openModal();
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getCountryList = () => {
      const countryListModel: CountryListModel = {
        country: {},
        page: 1,
        pageSize: 50,
      };

      countryController
        .countryList(countryListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((country) => {
              countryList.value.push(country);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    function openModal() {
      const modalElement = document.getElementById("otp_modal");
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
    }

    onMounted(async () => {
      getCountryList();
    });

    return {
      onSubmitLogin,
      phone,
      submitButton,
      generateOTPModel,
      countryList,
      submit,
    };
  },
  directives: { mask },
});
</script>
