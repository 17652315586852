
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/presentation/store/enums/StoreEnums";
import { useStore } from "vuex";
import OTP_Modal from "@/presentation/views/OTPModule.vue";
import * as Yup from "yup";
import { mask } from "vue-the-mask";
import { useI18n } from "vue-i18n";
import { GenerateOTPModel } from "@/domain/auth/generateOTP/model/GenerateOTPModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { CountryListModel } from "@/domain/country/countryList/model/CountryListModel";
import * as bootstrap from "bootstrap";

export default defineComponent({
  name: "login",
  components: {
    Field,
    Form,
    ErrorMessage,
    OTP_Modal,
  },
  setup() {
    const { t } = useI18n();
    const swalNotification = new SwalNotification();
    const store = useStore();
    const otpController = store.state.ControllersModule.otpController;
    const countryController = store.state.ControllersModule.countryController;

    const submitButton = ref<HTMLElement | null>(null);
    const submit = ref<boolean>(true);
    const generateOTPModel = ref<GenerateOTPModel>({
      countryCode: 397,
    });

    const countryList = ref<CountryListModel[]>([]);

    //Create form validation object
    const phone = Yup.object().shape({
      phone: Yup.string()
        .required(t("validators_error.required"))
        .label("Phone")
        .min(15, t("validators_error.phoneNumberFull")),
    });

    //Form submit function
    const onSubmitLogin = () => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      generateOTPModel.value.phoneNumber = generateOTPModel.value.phoneNumber
        ?.toString()
        .replace(/\D/g, "");

      store.dispatch("phoneNumber", generateOTPModel.value.phoneNumber);
      store.dispatch("countryCode", generateOTPModel.value.countryCode);

      otpController
        .generateOTP(generateOTPModel.value)
        .then((response) => {
          if (response.isSuccess) {
            submit.value = true;
            store.dispatch("expireTime", response.getValue().expireTime);
            submitButton.value?.removeAttribute("data-kt-indicator");
            store.dispatch(
              "remainingAttempts",
              response.getValue().remainingAttempts
            );
            openModal();
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getCountryList = () => {
      const countryListModel: CountryListModel = {
        country: {},
        page: 1,
        pageSize: 50,
      };

      countryController
        .countryList(countryListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((country) => {
              countryList.value.push(country);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    function openModal() {
      const modalElement = document.getElementById("otp_modal");
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
    }

    onMounted(async () => {
      getCountryList();
    });

    return {
      onSubmitLogin,
      phone,
      submitButton,
      generateOTPModel,
      countryList,
      submit,
    };
  },
  directives: { mask },
});
